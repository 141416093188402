import {
  requiredText,
  email,
  urlText,
  phoneText,
  alphaNumeric,
  alphaNumericLength,
  cityText,
  matchPassword,
  requiredOnlyText,
  nameText,
  emailText,
  emailReqText,
  multipleEmailTextNotReq,
  emailTextNotReq,
  requiredEmail,
  characterLimitRequired,
  zipText,
  maxCharacterLimit
} from '../../../../services/schemas/genericSchemas';
import * as Yup from 'yup';

/**
 * Setup schema for contact information of the company
 * requires requiredText schema from genericSchemas
 **/

export const contactInformationSchemas = Yup.object().shape({
  primaryContactName: nameText,
  primaryContactEmail: null,
  primaryContactPhone: phoneText,
  primaryContactAltPhone: phoneText,
  invoiceEmail: multipleEmailTextNotReq,
  officialBusinessName: characterLimitRequired(100),
  address: null,
  city: maxCharacterLimit(50),
  state: maxCharacterLimit(50),
  zip: zipText,
  serviceArea: null,
  country: requiredText
});
export const facebookPixelSectionSchema = Yup.object().shape({
  facebookPixelAccount: requiredText
});
export const ga4SectionSchema = Yup.object().shape({
  googleAnalyticsMeasurementId: requiredText
});
export const customTrackingSectionSchema = Yup.object().shape({});
export const googleAnalyticsTrackingSectionSchema = Yup.object().shape({
  googleAnalyticsAccount: requiredText,
  googleAnalyticsDomain: urlText
});

export const facebookSchema = Yup.object().shape({
  // verificationToken: requiredText,
  // pageAccessToken: requiredText,
  // callbackURL: requiredText,
  // getStartedButtonGreetingText: requiredText,
  // facebookChatGreetingText: requiredText,
  // companyFacebookPageName: requiredText,
  // facebookPageID: requiredText
});
export const basicDetailsSchema = Yup.object().shape({
  domain: urlText
});

export const leadEmailSchema = Yup.object().shape({
  leadEmailAddresses: emailReqText,
  chatTranscriptEmailAddresses: emailText
});

export const callConnectSchema = (enable = false) =>
  Yup.object().shape({
    officeHoursTimezone: null,
    quickConnectPhoneNumber: phoneText,
    callConnectTranscriptOverride: null,
    callConnectTranscriptEmail: enable ? requiredEmail : emailTextNotReq
  });

export const companyHeaderSchema = Yup.object().shape({
  companyName: requiredText
});
export const reportHeaderSchema = Yup.object().shape({
  name: requiredText
});

export const userSchemas = Yup.object().shape({
  userId: requiredText,
  userName: requiredText,
  displayName: requiredText,
  email: email,
  userType: requiredOnlyText,
  confirmPassword: null,
  lockedOut: null,
  preferMobile: null
});

export const leadSchema = Yup.object().shape({
  documentVerified: null
});
